import Image, { ImageProps, StaticImageData } from 'next/image';
import React from 'react';
import logo from '@/assets/images/logo.png';

interface LazyImageProps extends Omit<ImageProps, 'src'> {
  src?: string | null | StaticImageData;
}

const LazyImage = (props: LazyImageProps) => {
  const { src, alt } = props;
  return (
    <Image
      {...props}
      alt={alt}
      src={src || logo}
      // className={`${className}  opacity-0 transition-opacity duration-500`}
      // onLoadingComplete={image => image.classList.remove('opacity-0')}
      priority
    />
  );
};

export default LazyImage;
